<template>
  <vca-field :label="$t('email.event.label')">
    <h3>{{ $t("email.event.event.label") }}</h3>
    <vca-dropdown
      v-model="event"
      :options="eventsList"
      label=""
      @open="open()"
      @search="request($event)"
      :placeholder="$t('email.event.placeholder')"
    ></vca-dropdown>
    <div v-if="event_id">
      <h3>{{ $t("email.event.group.label") }}</h3>
      <vca-row>
        <vca-checkbox
          v-for="entity in participationsTypes"
          v-model="value.recipient_group.state"
          :key="entity"
          :id="entity"
          >{{ $t("email.event.group." + entity) }} ({{
            participationsCount(entity)
          }})</vca-checkbox
        >
        <div
          v-if="participationsTypes.length == 0"
          class="vca-center highlight"
        >
          {{ $t("email.event.group.empty") }}
        </div>
      </vca-row>
    </div>
  </vca-field>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "EmailEventGroup",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      store: "events/pg",
      currentFilter: {
        id: null,
        type: [],
        crews: [],
        entities: [],
        start_date: null,
        end_date: null,
        event_state: [],
        only_apply: false,
        missing_applications: false,
      },
    };
  },
  created() {
    console.log();
    this.$store.commit(`${this.store}/route`, "events/event");
    this.$store.commit(`${this.store}/context`, "events/pg");
    if (!this.hasSystemPermission()) {
      this.currentFilter.crews = [this.currentUser.crew_id];
    }
    this.currentFilter.event_state = [...this.publishedStates, "canceled"];
    if (this.value.recipient_group.event_id) {
      this.pg.filter = this.value.recipient_group.event_id;
      this.requestParticipations(this.value.recipient_group.event_id);
    }
    this.filter = this.currentFilter;
    this.$store.commit(`${this.store}/currentPageSize`, 100);
    this.$store.dispatch({
      type: `${this.store}/request`,
      data: { initial: true },
    });
  },
  methods: {
    participationsCount(type) {
      return this.participations.filter((item) => {
        return item.status == type;
      }).length;
    },
    open() {
      this.pg.filter = "";
      this.$store.commit(`${this.store}/currentPageSize`, 100);
      this.$store.dispatch({
        type: `${this.store}/request`,
        data: { abort: true },
      });
    },
    request(str) {
      this.$store.commit(`${this.store}/currentPageSize`, 100);
      this.pg.filter = str;
      this.$store.dispatch({
        type: `${this.store}/request`,
        data: { abort: true },
      });
    },
    requestParticipations(eventID) {
      let data = { event_id: eventID };
      this.$store
        .dispatch({ type: "events/participations/queryList", data: data })
        .then((response) => {
          this.$store.commit(
            "events/participations/list",
            !response || response.length == 0 ? [] : response
          );
        });
    },
  },
  computed: {
    ...mapGetters({
      events: "events/pg/list",
      currentUser: "user/current",
      publishedStates: "events/publishedstates",
      participations: "events/participations/list",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    eventsList() {
      return this.events.filter((el) => {
        el.label = el.name;
        el.title = el.name;
        el.value = el.id;
        return true;
      });
    },
    participationsTypes() {
      return this.participations
        ? [...new Set(this.participations.map((item) => item.status))].filter(
            (item) => {
              return ["confirmed", "requested", "rejected"].includes(item);
            }
          )
        : [];
    },
    event_id() {
      return this.event.length > 0 ? this.event[0].value : null;
    },
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    event: {
      get() {
        return [{ value: this.value.recipient_group.event_id }];
      },
      set(value) {
        let eventID = value.length ? value[0].value : null;

        this.requestParticipations(eventID);

        this.value.recipient_group.event_id = eventID;
      },
    },
  },
};
</script>
